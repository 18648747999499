<template>
  <div>
    <b-container fluid>
      <!-- <b-spinner
        type="grow"
        label="Loading..."
        style="margin-top: 1rem; margin-bottom:1rem;"
        ></b-spinner> -->
      <img src="@/assets/UDmQgAZXIOQ.jpg" width="250" style="margin-bottom: 2rem; margin-top: 20px; border-radius: 50%; border: thin solid black;" />
      <h5>Currently:</h5>
      <div style="margin: 2rem;">
        <p>
          <font-awesome-icon icon="laptop-code" size="lg" style="margin-right: 0.5rem;"/> DevOps Engineer at Paramount
        </p>
        <!-- <p>
          <font-awesome-icon icon="book-open" size="lg" style="margin-right: 0.5rem;"/> PhD Studies Computer Science
          <br>
          Department of Computing and Software
          <br>
          McMaster University
        <br> -->
        <a href="mailto:viktor_melnyk@protonmail.com" target="_top">
          viktor_melnyk@protonmail.com
        </a>
        <a href="mailto:viktor@vmelnyk.com" target="_top">
          viktor@vmelnyk.com
        </a> 
        </p>
      </div>
      <h5>Resources & Contact:</h5>
      <div style="margin: 1.25rem;">
        <a href="https://www.linkedin.com/in/viktor-melnyk-400241144/">
          <font-awesome-icon :icon="['fab', 'linkedin']" size="lg" />
        </a>
        <a href="mailto:viktor_melnyk@protonmail.com" target="_top">
          <font-awesome-icon icon="envelope" size="lg" />
        </a>
        <a href="https://gitlab.com/kendoka.melnyk">
          <font-awesome-icon :icon="['fab', 'gitlab']" size="lg" />
        </a>
        <a href="https://twitter.com/victor_melnyk">
          <font-awesome-icon :icon="['fab', 'twitter']" size="lg" />
        </a>
      </div>
    </b-container>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faLaptopCode, faBookOpen } from "@fortawesome/free-solid-svg-icons";
import {
  faGitlab,
  faTwitter,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

library.add(faEnvelope, faLaptopCode, faBookOpen, faGitlab, faTwitter, faLinkedin);

export default {
  name: "Sidebar",
};
</script>

<style scoped>
  a {
    color: rgb(50, 50, 50);
    margin: 0.5rem;
  }
  .container-fluid {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
</style>
